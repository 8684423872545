import { useRefs, useEvent } from '../framework'
import { events } from '../events'
import {
  getConfiguratorOptions,
  getCurrentOptions,
  getProductConfiguratorData,
  setOption,
  translateUrlVariantToOptions,
} from '../configurator'

export default async ref => {
  if (!ref.variantSelector) return

  const res = await fetch('?section_id=load-more-colors')
  const text = await res.text()
  const parser = new DOMParser()
  const markup = parser.parseFromString(text, 'text/html')

  // console.log('extra markup', markup)

  const lists = markup.querySelectorAll('ul')

  lists.forEach(list => {
    const target = document.querySelector(`[data-parent=${list.dataset.target}]`)

    target.append(...list.children)
  })

  translateUrlVariantToOptions()

  const allData = getConfiguratorOptions()
  const productData = getProductConfiguratorData()

  ref.variantSelector.forEach(variantSelector => {
    const refs = useRefs({ target: variantSelector, asArray: true })

    updateUI(refs, allData)

    refs.selector.forEach(button => {
      button.addEventListener('click', () => {
        const { type, handle } = button.dataset
        setOption(type, handle)
        updateUI(refs, allData)
        useEvent.dispatch(events.product.variantSelectorUpdate)
      })
    })

    function handleAvailableOnline() {
      const url = new URL(window.location)

      if (!url.searchParams.has('available')) {
        // activate all
        refs.selector.forEach(button => button.classList.remove('disabled'))
        return
      }

      if (!productData) return

      const availableOptions = productData.variants
        .map(variant => variant.options)
        .flat()
        .reduce((acc, cur) => {
          if (!acc[cur.type]) acc[cur.type] = []

          if (!acc[cur.type].includes(cur.handle)) {
            acc[cur.type].push(cur.handle)
          }

          return acc
        }, {})

      const availableFamilies = [
        ...new Set(
          productData.product_options
            .find(option => option.type === 'upholstery')
            ?.options?.filter(option => {
              return availableOptions['upholstery'].includes(option.handle)
            })
            .map(option => option.family)
        ),
      ]

      refs.selector.forEach(button => {
        const { type, handle, family } = button.dataset

        if (family) {
          button.classList.toggle(
            'disabled',
            !availableFamilies.includes(family)
          )
        } else {
          button.classList.toggle(
            'disabled',
            !availableOptions[type].includes(handle)
          )
        }
      })
    }

    handleAvailableOnline()

    useEvent.listen(events.product.availableOnline, handleAvailableOnline)
  })

  return () => {}
}

function updateUI(refs, allData) {
  const currentOptions = getCurrentOptions({ asObject: true })

  // activate initially chosen options
  activateButtons(refs, currentOptions)

  // show the initial chosen color column
  activateColorList(refs, currentOptions)

  // prints the name of the current choice in the mobile dropup
  changeCurrentOptionMobileLabel(refs, currentOptions, allData)

  // disable options if another option overwrites
  disableOverwrittenOptions(refs, currentOptions)
}

function activateButtons(refs, currentOptions) {
  // console.log('currentOptions', currentOptions)

  refs.selector.forEach(button => {
    const { type, handle, family } = button.dataset

    if (family) {
      // activate if the button is part of the chosen family (shell)
      button.classList.toggle('active', currentOptions[type]?.family === family)
    } else {
      // if (family === 'polypropylene') {
      //   console.log(type, handle, family)
      // }
      // activate if the button is the chosen option
      button.classList.toggle('active', currentOptions[type]?.handle === handle)
    }
  })
}

function activateColorList(refs, currentOptions) {
  if (!refs.child) return

  refs.child.forEach(list => {
    if (currentOptions.shell?.handle === 'polypropylene') {
      const { type, parent } = list.dataset

      list.classList.toggle(
        'hidden',
        type !== 'shell' || currentOptions.shell?.handle !== parent
      )

      return
    }

    const { type, parent } = list.dataset

    list.classList.toggle('hidden', currentOptions[type]?.family !== parent)
  })
}

function changeCurrentOptionMobileLabel(refs, currentOptions, allData) {
  // doesn't exist in the desktop variant selector
  if (!refs.currentChoice) return

  refs.currentChoice.forEach(label => {
    const { choice } = label.dataset

    const noUpholstery = currentOptions.shell?.handle === 'no-upholstery'

    if (choice === 'upholstery-family') {
      const family = allData.upholstery?.find(
        shell => shell.handle === currentOptions.upholstery.family
      )?.name

      label.textContent = !noUpholstery && family ? family : ''

      return
    }

    if (choice === 'upholstery' && noUpholstery) {
      label.textContent = ''
      return
    }

    if (!currentOptions[choice]) {
      label.textContent = ''
      return
    }

    label.textContent = currentOptions[choice].name
  })
}

function disableOverwrittenOptions(refs, currentOptions) {
  if (!refs.group) return

  refs.group.forEach(group => {
    const { disabledBy } = group.dataset

    if (!disabledBy) {
      group.classList.remove('disabled')
      return
    }

    const options = disabledBy.split(';')

    const disable = options.find(option => {
      const [type, handle] = option.split(':')

      return currentOptions[type]?.handle === handle
    })

    group.classList.toggle('disabled', !!disable)
  })
}
